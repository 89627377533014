<template>
  <section>
    <v-container fluid>
      <v-row>
        <v-col md="12">
          <PageHeader :title="headercontent.title" />
        </v-col>
        <v-col md="6">
          <v-form
            ref="form"
            v-model="valid"
            lazy-validation
            class="form-section"
          >
            <v-row>
              <v-col cols="12">
                <v-select
                  :items="allUser.results"
                  item-value="userHandle"
                  item-text="fullname"
                  label="Select User"
                  v-model="userHandle"
                  outlined
                ></v-select>
              </v-col>
              <v-col cols="12">
                <v-select
                  :items="allMenus.results"
                  item-value="_id"
                  item-text="title"
                  label="Select Menu"
                  v-model="menu"
                  outlined
                ></v-select>
              </v-col>
              <v-col cols="12">
                <v-select
                  :items="statuses"
                  v-model="status"
                  item-value="status"
                  item-text="name"
                  label="Select Status"
                  outlined
                ></v-select>
              </v-col>

              <v-col cols="12" class="submitbtn">
                <v-btn
                  :disabled="!valid"
                  class="submitbutton btn-dark"
                  @click="assignMenu"
                >
                  Add {{ headercontent.title }}
                </v-btn>
                <!-- <v-progress-circular
         
                  indeterminate
                ></v-progress-circular> -->
              </v-col>
            </v-row>
          </v-form>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
import PageHeader from "@/components/dashboard/PageHeader";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "AddMenuAssign",
  components: { PageHeader },
  data: () => ({
    valid: true,
    items: ["Foo", "Bar", "Fizz", "Buzz"],
    headercontent: {
      title: "Add Menu Access",
      addlink: "/dashboard/training/add",
      downloadlink: true,
    },
    statuses: [
      { name: "Yes", status: true },
      { name: "No", status: false },
    ],
    status: true,
    userHandle: "",
    menu: "",
    sort: 0,
  }),
  methods: {
    ...mapActions(["getUserForMenu", "getMenus", "menuAssign"]),
    validate() {},
    assignMenu() {
      let data = {
        id: this.menu,
        userHandle: this.userHandle,
        status: this.status,
        sort:0
      };
      // console.log(data)
      this.menuAssign(data);
    },
  },
  computed: {
    ...mapGetters(["allUser", "allMenus"]),
  },
  created() {
    this.getUserForMenu();
    this.getMenus();
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";
.btn-dark {
  background: $tagcolor !important;
  color: $white;
  width: 250px;
  min-height: 50px;
}

.imagebox {
  display: flex;
  max-width: 30%;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  padding: 20px;
  margin-top: 20px;
  label {
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
  }
}
</style>
